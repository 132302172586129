body {font-family: Arial, Helvetica, sans-serif;}
* {box-sizing: border-box;}

input[type=text], select, textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #e4ebf3;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type=submit] {
  background-color: #04AA6D; 
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type=submit]:hover {
  background-color: #45a049;
}

.containerForm {
  border-radius: 5px;
  /* background-color: #f2f2f2; */

  width: 80%;
 
  /* background-color: #04AA6D; */
}
.form{
    justify-content: center;
    align-items: center;
    padding-left: 200px;
    background-color: #dde8f5;
}