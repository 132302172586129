/* .container {
    font-family: Arial, sans-serif;
    color: #010b96;
    padding: 20px;
    background-color: #f4f4f4;
  }
  
  .heading {
    text-align: center;
    margin: 20px 0;
  }
  
  .image {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }
  
  .section {
    margin: 20px 0;
  }
  
  .pricing-plan {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
    margin: 10px 0;
  }
  
  .plan-title {
    color: #010b96;
  }
  
  .plan-price {
    font-size: 1.2em;
    font-weight: bold;
  }
  
  .list-item {
    margin: 5px 0;
  } */
  @media (max-width: 768px) {
    .container-about {
        flex-direction: column;
        align-items: center;
    }

    .text, .image {
        flex: 1 1 100%;
        text-align: left;
        margin-right: 0; /* Reset margin for smaller screens */
    }

    .text {
        padding: 10px; /* Reset padding for smaller screens */
    }
}