body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container-about {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
}

.text {
    flex: 1 1 40%;
    padding: 10px 10px 10px 20px; /* top, right, bottom, left */
    margin-right: 30px; /* Space between text and image */
}

.image {
    flex: 1 1 55%;
    padding: 30px;
    
}

.image img {
    max-width: 100%;
    height: auto;
    display: block;
}

@media (max-width: 768px) {
    .container-about {
        flex-direction: column;
        align-items: center;
    }

    .text, .image {
        flex: 1 1 100%;
        text-align: center;
        margin-right: 0; /* Reset margin for smaller screens */
    }

    .text {
        padding: 10px; /* Reset padding for smaller screens */
    }
}
