@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.carousel-caption h1 {
  animation: fadeInUp 1s ease-in-out;
}

.carousel-caption h3 {
  animation: fadeInUp 1.5s ease-in-out;
}
.caption-zoom h1 {
  margin: 0; /* Reset margin to avoid unwanted spacing */
}

.caption-zoom .welcome-title {
  font-family: fantasy;
  padding-bottom: 100px; /* Adjust as needed */
  margin-bottom: 10px; /* Reduce spacing between titles */
}

.caption-zoom .corporate-title {
  font-family: initial;
}

