.card-Container {
  display: flex;
  flex-direction: row; /* Aligns the cards in a row */
  flex-wrap: wrap; /* Allows the cards to wrap to the next line if they don't fit in one row */
  gap: 5px; /* Adjust the gap between cards as needed */
}

.cardConatiner {
  background: #f0f0f0;
  border: 1px solid #ccc;
  padding: 25px;
  width: 200px; /* Adjust the width of each card */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}
.card h3{
    color: #010b96;
    font-size: "18px"
}
.card p{
  color: #010b96;
      font-size: "16px"
}

/* Media Queries for Mobile View */
@media (max-width: 768px) {
  .coinwithhand .container {
    padding: 0;
  }

  .row {
    flex-direction: column;
  }

  .col-12.col-lg-6 {
    width: 100%;
  }

  .card-Container {
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 768px) {
    .container-about {
        flex-direction: column;
        align-items: center;
    }

    .text, .image {
        flex: 1 1 100%;
        text-align: center;
        margin-right: 0; /* Reset margin for smaller screens */
    }

    .text {
        padding: 10px; /* Reset padding for smaller screens */
    }
}