.slider-container {
  margin: 0 auto;
  padding: 20px;
  max-width: 1200px;
}

.slider-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.slider-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flip-box-img {
  margin: 0 auto;
  transition: transform 0.3s ease;
}

.flip-box-img:hover {
  transform: scale(1.1);
}

/* Mobile View */
@media (max-width: 768px) {
  .slick-slide {
    padding: 10px;
  }

  .flip-box-img {
    width: 100px; /* Adjust the width for mobile view */
    margin: 0 10px; /* Adjust margin between images */
  }
}

@media (max-width: 480px) {
  .flip-box-img {
    width: 80px; /* Further adjust width for smaller screens */
    margin: 0 3px; /* Further adjust margin between images */
  }
}
