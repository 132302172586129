/* Card Container */
.card {
  flex: 1;
  /* border: 1px solid #ccc; */
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  padding: 80px;
  margin: 20px;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.card:hover {
  transform: scale(1.05); /* Slightly scale up on hover */
}

/* Arrow Icon Styling */
.card-body {
  color: white;
  font-size: 2rem;
  opacity: 0;
  transition: opacity 0.3s ease; /* Smooth transition */
}

.card:hover .card-body {
  opacity: 1; /* Fade in on hover */
}

.learn-more a {
  display: inline-block;
  margin-top: 20px;
  color: #1d4ed8;
  font-weight: bold;
  text-decoration: none;
  padding: 10px 20px;
  border: 2px solid #1d4ed8;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.learn-more a:hover {
  background-color: #1d4ed8;
  color: white;
}

/* Ensure images scale properly */
.card::before {
  content: '';
  position: absolute;
  border-radius: 16px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  transition: transform 0.3s ease; /* Smooth transition */
  z-index: -1;
}

.card:hover::before {
  transform: scale(1.1); /* Slightly scale the background image */
}

/* Adjust for responsiveness */
@media (max-width: 768px) {
  .card {
    height: 250px; /* Smaller height for smaller screens */
  }
}

@media (max-width: 576px) {
  .card {
    height: 200px; /* Even smaller height for mobile screens */
  }
}
