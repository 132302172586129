
.navbar {
  list-style-type: none;
  display: flex;
  /* background-color: #ffffff; */
}
.navbar-custom {
  background-image: url('/src//components/assets/geometric.jpg'); /* Add your image path here */
  /* background-size: cover;
 background-position: center;  */
}
nav .title{ 
  font-size: 1.5rem;
  margin: 1rem;
  font-weight: bold;
  text-decoration: none;
  color:#ffffff;
}
nav ul{
  display: flex
}
nav ul li{
  list-style: none;
}
nav ul li a{
  display: block;
  text-decoration: none;
  color:#ffffff;
  padding: 0.5rem;
  margin: 0 0.5rem;
  border-radius: 0.5rem;
}
nav .menu{
  display: none;
position: absolute;
top: 0.7rem;
right: 0.5rem;
flex-direction: column;
justify-content: space-between;
width: 2.25rem;
height: 2rem;
}
nav .menu span{
  height: 0.4rem;
  width: 100%;
  background-color: #ffffff;
  border-radius: 0.2rem;
}


.nav-item a{
  color: #ffffff;
  position: relative;
  font-weight: 700;
  font-size: 13px;
}

.nav-link {
  color: #010b96;
  padding: 14px 20px;
  text-decoration: none;
}
.dropdown-menu {
  display: none;
  position: absolute;
  background-color: #df1818;
  min-width: 160px;
  box-shadow: #010b96;
  z-index: 1;
  overflow: visible;

}

.dropdown-menu .dropdown-item {
  padding: 12px 16px;
  display: block;
  text-decoration: none;

}

.nav-item:hover .dropdown-menu {
  display: block;
}
.active {
  /* background-color: #007bff; */
  color: #010b96;
}

.dropdown-menu .dropdown-item {
  color: #010b96; /* Default color */
  cursor: pointer; /* Change cursor to pointer */
}
.navbar-nav .nav-link.dropdown-toggle:hover {
  color: #010b96; /* Change text color on hover */
}
/* Navbar.css */

/* Add hover effect for all nav items */
.nav-item a:hover {
  color: #eaa424   !important;
}

/* Customize dropdown items hover */
.dropdown-item a:hover {
  color: #010b96 !important;
}

/* For other customization if needed */
.nav-item .nav-link {
  color: #fff; /* Default color */
  transition: color 0.3s ease-in-out;
}

.dropdown-item {
  color: #010b96; /* Default dropdown item color */
  transition: color 0.3s ease-in-out;
}

@media (max-width:1000px) {
  nav .menu{
    display: flex;
  }
  nav{
    flex-direction: column;
    align-items: start  ;
  }
  nav ul{
    display: none;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0.25rem;
  }
  nav ul.open{
    display: flex;
  }
  nav ul li{
    width: 100%;
    text-align: center;
  }
  nav ul li a{
margin: 0.2rem 0.5rem;
  }
}
/* Tab view styles (between mobile and desktop) */
@media (min-width: 768px) and (max-width: 1024px) {
  .nav-item a {
    color: #ffffff; /* Change color for nav items in tab view */
    cursor: pointer; /* Add pointer cursor */
  }

  .dropdown-item a {
    color: #ffffff; /* Change color for dropdown items in tab view */
    cursor: pointer; /* Add pointer cursor */
  }

  .nav-link.dropdown-toggle {
    color: #ffffff; /* Change dropdown toggle color in tab view */
    cursor: pointer; /* Add pointer cursor */
  }

  .dropdown-menu {
    background-color: #ba2525; /* Adjust dropdown background if needed */
  }

  .dropdown-item {
    color: #ffffff; /* Change dropdown item color */
    cursor: pointer; /* Add pointer cursor */
  }

  .dropdown-item:hover {
    background-color: #f8f9fa; /* Optional: Add a hover effect for dropdown items */
    color: #ffffff; /* Maintain hover color */
    cursor: pointer; /* Ensure pointer cursor on hover */
  }
}

/* Mobile view styles */
@media (max-width: 767px) {
  .nav-item a {
    color: #ffffff; /* Change color for nav items in mobile view */
    cursor: pointer; /* Add pointer cursor */
  }

  .dropdown-item a {
    color: #ffffff;/* Change color for dropdown items in mobile view */
    cursor: pointer; /* Add pointer cursor */
  }

  .nav-link.dropdown-toggle {
    color: #ffffff; /* Change dropdown toggle color in mobile view */
    cursor: pointer; /* Add pointer cursor */
  }

  .dropdown-menu {
    background-color: #fff; /* Adjust dropdown background if needed */
  }

  .dropdown-item {
    color: #ffffff; /* Change dropdown item color */
    cursor: pointer; /* Add pointer cursor */
  }

  .dropdown-item:hover {
    background-color: #f8f9fa; /* Optional: Add a hover effect for dropdown items */
    color: #ffffff; /* Maintain hover color */
    cursor: pointer; /* Ensure pointer cursor on hover */
  }
}