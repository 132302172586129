/* Style for previous arrow */
.slick-prev {
    left: 10px; /* Horizontal position */
    top: 50%;   /* Vertical centering */
    transform: translateY(-50%); /* Center vertically */
    z-index: 2;
    background-color: rgba(191, 155, 155, 0.5); /* Optional: semi-transparent background */
    border-radius: 50%;
    width: 20px; /* Adjust size */
    height: 20px; /* Adjust size */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Style for next arrow */
  .slick-next {
    right: 10px; /* Horizontal position */
    top: 50%;    /* Vertical centering */
    transform: translateY(-50%); /* Center vertically */
    z-index: 2;
    background-color: rgba(191, 155, 155, 0.5); /* Optional: semi-transparent background */
    border-radius: 50%;
    width: 20px; /* Adjust size */
    height: 20px; /* Adjust size */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Customize arrow icons */
  .slick-prev-icon:before,
  .slick-next-icon:before {
    font-size: 30px;  /* Adjust size of the arrow icon */
    color: white;     /* Change this to your desired arrow color */
  }
  
  /* Optional: Hover effect */
  .slick-prev:hover:before,
  .slick-next:hover:before {
    color: #edd369; 
   /* Hover color */
  }
  