.header {
  background-color: #a4c991;
  padding: 10px 20px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header nav ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
  width: 100%;
}
.header nav li{
  height: 50px;
}
nav a{
  height: 100%;
  padding: 0 30px;
  text-decoration: none;
  display: flex;
  align-items: center;
  color: black;
}
nav li:first-child{
  margin-right: auto;
}
.app{
  display: inline;
}


