/* ITServices.css */
@media (max-width: 768px) {
    /* Adjust font size for headings */
    h1 {
      font-size: 1.5rem;
      text-align: center;
    }
  
    /* Adjust paragraph font size and alignment */
    p {
      font-size: 0.9rem;
      text-align: justify;
    }
  
    /* Ensure images are responsive */
    .image img {
      width: 100%;
      height: auto;
    }
  
    /* Adjust padding and margin for sections */
    .coinwithhand {
      padding: 20px 15px;
    }
  
    .backgre {
      padding: 10px 0;
    }
  
    /* Adjust container for better layout on mobile */
    .container {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  