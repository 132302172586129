/* Refund.css */
.refund-container {
    padding: 20px;
    text-align: center;
  }
  
  .refund-form {
    max-width: 600px;
    margin: 0 auto;
    text-align: left;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .refund-button {
    background-color: #010b96;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .refund-button:hover {
    background-color: #0056b3;
  }
  