.checkout-container {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .checkout-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group select {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  
  .checkout-button {
    background-color: #010b96;
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
  }
  
  .checkout-button:hover {
    background-color: #010080;
  }
  input::placeholder {
    color: #010080; /* Replace with your desired color */
    opacity: 1; /* Make sure the placeholder text is fully opaque */
  }
  .refund-link {
    display: inline-block;
    color: #010b96;
    text-decoration: none;
    font-weight: bold;
    transition: transform 0.3s ease-in-out; /* Smooth transition for movement */
  }
  
  .refund-link:hover {
    transform: translateX(10px); /* Moves the link 10px to the right */
  }
  
  /* Optional: Add a keyframes animation for a bouncing effect */
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
  
  .refund-link {
    animation: bounce 2s infinite; /* Apply bounce animation */
  }
  