.container-about {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
  }
  
  .text {
    animation: fadeInText 2s ease-in-out;
  }
  
  @keyframes fadeInText {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .image img {
    animation: slideInImage 2s ease-in-out;
  }
  
  @keyframes slideInImage {
    0% {
      opacity: 0;
      transform: translateX(-50px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  .container-about {
    display: flex;
    flex-direction: row; /* Align items horizontally */
    justify-content: space-between; /* Add space between text and image */
    align-items: center;
    padding: 20px;
  }
  
  .text {
    flex: 1; /* Takes up available space */
    padding-right: 20px; /* Add space between text and image */
  }
  
  .image {
    flex: 1; /* Takes up available space */
    text-align: right; /* Align image to the right */
  }
  /* Styles for mobile screens */
@media (max-width: 768px) {
    .container-about {
      flex-direction: column;
      align-items: center;
    }
  
    .text {
      margin-right: 0;
      text-align: center;
    }
  
    .image {
      margin-top: 20px;
    }
  }
  