span h6 p{
  /* background-color: #000d32; */
    color: #fff;
    padding: 10px 0;
  }
 .footer h6 {
    color: #fff;
  }
  .footer p {
    color: #fff;
  }
  div a{
    color: #fff;
  }
span{
    color: #fff;
  }
  div a{
    color: #fff;
    text-decoration: none;
  }


  @media (max-width: 767px) {
    .footer p, 
    .footer h6 {
      text-align: center;
    }
  
    .footer a {
      text-align: center;
      display: block;
    }
  }
  