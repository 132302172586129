html {
    font-family: sans-serif;
    box-sizing: border-box;
  }
  
  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  .text-center {
    text-align: center;
  }
  
  .color-white {
    color: #fff;
  }
  
  .box-container {
    /* background-color: #010b96; */
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    /* padding: 10px 10px; */
    width: 100%;
    height: 60%;
  }
  
  @media (max-width: 600px) {

    .box-container {
  
        flex-direction: column;
  
        padding: 10px;
  
    }
  }
  /* Responsive adjustments */
@media (max-width: 1024px) {
  .box-item {
    width: calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .box-item {
    width: calc(100% - 20px);
  }

  .flip-box-header {
    font-size: 1.2em;
  }

  .flip-box-button {
    padding: 8px 16px;
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .flip-box-header {
    font-size: 1em;
  }

  .flip-box-button {
    padding: 6px 12px;
    font-size: 0.8em;
  }
}


  .box-item {
    position: relative;
    /* -webkit-backface-visibility: hidden; */
    padding: 20px;
    width: 905px;
    margin-bottom: 35px;
    max-width: 100%;
  }
  
  .flip-box {
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    perspective: 1000px;
    -webkit-perspective: 1000px;
  }
  
  .flip-box-front,
  .flip-box-back {
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    min-height: 250px;
    -ms-transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
    transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
    -webkit-transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-box-front {
    -ms-transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  
  .flip-box:hover .flip-box-front {
    -ms-transform: rotateY(-180deg);
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  
  .flip-box-back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    
    -ms-transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  
  .flip-box:hover .flip-box-back {
    -ms-transform: rotateY(0deg);
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  
  .flip-box .inner {
    position: absolute;
    left: 0;
    width: 100%;
    padding: 60px;
    outline: 1px solid transparent;
    -webkit-perspective: inherit;
    perspective: inherit;
    z-index: 2;
    
    transform: translateY(-50%) translateZ(60px) scale(.94);
    -webkit-transform: translateY(-50%) translateZ(60px) scale(.94);
    -ms-transform: translateY(-50%) translateZ(60px) scale(.94);
    top: 50%;
  }
  
  .flip-box-header {
    font-size: 20px;
    line-height: 1.0em;
    color: #010b96;
  }
  
  .flip-box p {
    font-size: 20px;
    line-height: 1.5em;
    color: #010b96;
  }
  
  .flip-box-img {
    margin-top: 20px;
    background-color: #010b96;
    border-radius: 24px;
  }

  
  .flip-box-button {
    background-color: transparent;
    border: 2px solid  #010b96;
    border-radius: 2px;
    color: #010b96;
    cursor: pointer;
    font-size: 10px;
    font-weight: bold;
    margin-top: 15px;
    padding: 10px 10px;
    text-transform: uppercase;
  }