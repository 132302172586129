/* styles.css */
@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeInUp {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
   
  .paragraph {
    animation: fadeInUp 1s ease-in-out;
  }
  
  .paragraph-delayed {
    animation: fadeInUp 1.5s ease-in-out;
  }
  